<template>
  <div>
    <v-btn
      small
      fab
      dark
      fixed
      top="top"
      right="right"
      class="setting-fab mt-10"
      color="info"
      @click.stop="show = !show"
    >
      <v-icon>fas fa-question</v-icon>
    </v-btn>
    <v-navigation-drawer
      class="setting-drawer"
      temporary
      right
      v-model="show"
      hide-overlay
      width="370"
      fixed
    >
      <v-list class="pa-1" three-line>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="https://storage.googleapis.com/img.fod.live/static/img/question-mark-1750942_1280_xs.png"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Help </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="cookStep == 1">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.food_name') }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                $t('Common.food_name_sub')
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.food_category') }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ $t('Common.food_category_sub') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="long_h my-3">
            <v-list-item-content class="long_h">
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.food_detail') }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ $t('Common.food_detail_sub') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="mt-4">
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3">{{
                $t('Common.food_ingredients')
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                $t('Common.food_ingredients_sub')
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.food_weight') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('Common.food_weight_sub') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="cookStep == 2">
          <v-subheader>Price</v-subheader>

          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Price</v-list-item-title>
              <v-list-item-subtitle
                >Unit Price of a serving. For example $10 for each
                dish</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Minimum Order </v-list-item-title>
              <v-list-item-subtitle
                >Minimum amount a buyer should order. For example
                10</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Total Serving or Units cooking</v-list-item-title
              >
              <v-list-item-subtitle
                >Total amount you are cooking or cooked. For example 50
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="cookStep == 3">
          <v-subheader>Photos</v-subheader>

          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Tags:</v-list-item-title>
              <v-list-item-subtitle
                >Try to add tags for the photos. This will help buyers to find
                your products faster</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    isNew: {
      Type: Boolean
    },
    cookStep: {
      Type: Number
    }
  },
  watch: {
    isNew(newValue, oldValue) {}
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    if (!this.$vuetify.breakpoint.xs) {
      this.show = this.isNew
      console.log('First Dish should show help')
    }
  }
}
</script>
<style>
.long_h {
  min-height: 120px;
  height: auto;
  padding-top: 10px;
  margin-top: 10px;
}
</style>
